import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { getMenu, searchKeyword, searchMain } from '../../pages/api/hello';
import environment from '../../utils/environment';
import { Badge } from "antd";
import PaymentContext from "../../store/PaymentContext";
import { useDebounce } from '../../utils/format';
import { setStorage } from '../../utils/utils';
import dynamic from 'next/dynamic';
// import PopupMaintain from '../PopupMaintaince';
const DynamicPopupMaintain = dynamic(() => import('../PopupMaintaince'));

const HeaderComponent = () => {
  const [openMaintain, setOpenMaintain] = useState<boolean>(false);
  const [host, setHost] = useState("");
  const mobileRef: any = useRef(null);
  const [menuData, setMenuData] = useState([]);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const { rentCarts, sellCarts } = useContext(PaymentContext);
  function onOpenMenuMobile() {
    setOpenMenuMobile(!openMenuMobile);
  }

  function onCloseMenuMobile() {
    setOpenMenuMobile(false)
  }



  useEffect(() => {
    loadMenu();
    setHost(window.location.origin + "/");
  }, [])


  const loadMenu = async () => {
    try {
      let res: any = await getMenu();

      if (res) {
        setMenuData(res?.map((e: any) => {
          if (e.type === 1) {
            e.children = e.children?.map((item: any) => {
              item.url = "/" + item.code;
              return item;
            })
          }
          return e;
        }));
      }
    } catch (error) {

    }
  }


  const Menu = () => {
    const { asPath } = useRouter();
    const MenuBarItem = ({ item, index }: any) => {
      function isActive() {
        let cloneItem = { ...item }
        if (index === 0) {
          cloneItem.url = "/"
          return asPath === cloneItem?.url
        }

        return asPath === "/" + cloneItem?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') + "/";
      }

      const hasChildren = item.children && item.children.length > 0 ? true : false;
      return <div className={"h-full flex flex-col menuBarItem " + (hasChildren ? "parent" : "")}>
        {index === 0 ? <Link className="item"
          target={item.open_tab ? "_blank" : "_self"}
          // rel="noopener noreferrer"
          //href={host + item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ?? ""}
          onClick={()=>{
            if(item?.url === "/" || item?.url == "")
              window.location.href = "/"
            else{
              return
            }
          }}
          href={{ pathname: item?.url === "/" ? "" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
        >
          <div className={"text-[#666666] text-xs lg:text-xs xl:text-base  " + (isActive() ? "item-active" : "")}>{item.name}</div>
          {hasChildren && <Image
            className=""
            src="/asset/icon/ico_vector_down.svg"
            alt="Vector down"
            width={16}
            height={16}
          />}
        </Link> : item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ? <Link className="item" target={item.open_tab ? "_blank" : "_self"} 
        // rel="noopener noreferrer"
          //href={host + item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ?? ""}
          href={{ pathname: item?.url === "/" ? "/" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
        >
          <div className={"text-[#666666] text-xs lg:text-xs xl:text-base   " + (isActive() ? "item-active" : "")}>{item.name}</div>
          {hasChildren && <Image
            className=""
            src="/asset/icon/ico_vector_down.svg"
            alt="Vector down"
            width={16}
            height={16}
          />}
        </Link> : <div className="item">
          <div className={"text-[#666666] text-xs lg:text-xs xl:text-base  " + (isActive() ? "item-active" : "")}>{item.name}</div>
          {hasChildren && <Image
            className=""
            src="/asset/icon/ico_vector_down.svg"
            alt="Vector down"
            width={16}
            height={16}
          />}
        </div>}
        <div className={"divider " + (isActive() ? "divider-active" : "")} />
        {hasChildren && <MenuProductGroup item={item} items={item.children} />}
      </div>
    }

    const MenuProductGroup = ({ item, items }: any) => {
      const [products, setProducts]: any = useState(item?.children && item?.children?.length > 0 ? item?.children[0]?.children : []);
      const [products1, setProducts1] = useState([]);
      const [products2, setProducts2] = useState([]);
      const [products3, setProducts3] = useState([]);
      const [image, setImage] = useState<string | null>(null);
      const [active, setActive] = useState(0);
      function setProduct(items: any, img: string | null) {
        setProducts(items);
        setProducts1([]);
        setProducts2([]);
        setProducts3([]);
        setImage(img);
      }

      function unSet() {
        setProducts([]);
        setProducts1([]);
        setProducts2([]);
        setProducts3([]);
        setImage(null);
      }

      useEffect(() => {
        if (products && products[0]) {
          setProducts1(products[0].children);
          setStorage(products[0].id, products[0].name);
          setImage(products[0]?.image?.file_url ?? image);
        }

      }, [products])

      return <div onMouseLeave={() => { setActive(0) }} className="subMenu shadow-lg">
        <>
          <div className='flex w-[180px] xl:w-[297px] subMenu_layout flex-col'>
            {
              items?.map((e: any, index: number) => {
                return <MenuProductItem
                  setActive={setActive}
                  active={active}
                  key={"subMenu_" + index}
                  item={e}
                  index={index}
                  fncSet={setProduct}
                  fncUnset={unSet} />
              })
            }
          </div>
          {/* Level 1 */}
          <div className='flex mr-8 xl:mr-12 w-[180px] xl:w-[297px] subMenu_layout relative flex-col'>
            {
              products?.map((item: any, index: number) => {
                let group = items[active]?.code;
                return <Link onMouseOver={() => {
                  setProducts1(item.children);
                  setStorage(item.id, item.name);
                  setImage(item?.image?.file_url ?? image);
                }} key={"item_product_" + index}
                  //href={{ pathname: '/san-pham/[...product]', query: { product: [group, item?.code] } }}
                  //href={host + item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ?? ""}
                  href={item.type === 4 ? item.url : { pathname: (item?.url === "/" || item?.url == "") ? "" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
                  className='py-3 cursor-pointer subMenu_content_child text-xs lg:text-xs xl:text-base break_2'>{item?.name}</Link>
              })
            }
          </div>
          {/* Level 2 */}
          <div className='flex mr-8 xl:mr-12 w-[180px] xl:w-[297px] subMenu_layout relative flex-col'>
            {
              products1?.map((item: any, index: number) => {
                let group = items[active]?.code;
                return <Link onMouseOver={() => {
                  setProducts2(item.children);
                  setStorage(item.id, item.name);
                  setImage(item?.image?.file_url ?? image);
                }} key={"item_product_" + index}
                  //href={{ pathname: '/san-pham/[...product]', query: { product: [group, item?.code] } }}
                  //href={host + item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ?? ""}
                  href={item.type === 4 ? item.url : { pathname: item?.url === "/" ? "/" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
                  className='py-3 cursor-pointer subMenu_content_child text-xs lg:text-xs xl:text-base break_2'>{item?.name}</Link>
              })
            }
          </div>
          {/* Level 3 */}
          {products2 && products2.length > 0 && <div className='flex mr-8 xl:mr-12 w-[180px] xl:w-[297px] subMenu_layout relative flex-col'>
            {
              products2?.map((item: any, index: number) => {
                let group = items[active]?.code;
                return <Link onMouseOver={() => {
                  setProducts3(item.children);
                  setStorage(item.id, item.name);
                  setImage(item?.image?.file_url ?? image);
                }} key={"item_product_" + index}
                  //href={{ pathname: '/san-pham/[...product]', query: { product: [group, item?.code] } }}
                  //href={host + item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ?? ""}
                  href={item.type === 4 ? item.url : { pathname: item?.url === "/" ? "/" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
                  className='py-3 cursor-pointer subMenu_content_child text-xs lg:text-xs xl:text-base break_2'>{item?.name}</Link>
              })
            }
          </div>}
          {active !== -1 && image && <div className='image min-w-[300px] xl:min-w-[500px] max-h-[80vh] w-auto xl:w-[35%] py-6 relative'>
            <Image
              className=""
              src={image ? environment.baseImageUrl + image : "/asset/image/customer_1.webp"}
              alt="Menu image"
              title="Menu image"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              // objectFit='cover'
              // width={457}
              // height={410}
              layout='fill'
              objectFit='cover'
            />
          </div>}
        </></div>
    }

    const MenuProductItem = ({ item, index, fncSet, fncUnset, active, setActive }: any) => {

      return <div onMouseOver={() => {
        fncSet(item.children, item?.image?.file_url);
        setActive(index);
      }}
        id={"subMenu_" + index}
        key={"subMenuin_" + index}
        className="flex flex-row cursor-pointer items-center py-3 gap-2 w-full">
        <Link
          key={item.id}
          className={'subMenu_content_parent text-xs lg:text-xs xl:text-base break_2 uppercase ' + (active === index ? " active" : "")}
          //href={host + item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '') ?? ""}
          href={item.url === 4 ? item.url : { pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
        >
          {item.name}
        </Link>
        {item.children?.length > 0 && active === index && <Image
          className=""
          src="/asset/icon/ico_arrow_menu.svg"
          alt="Arrow Left"
          width={24}
          height={24}
        />}

      </div>
    }

    return <div className='menu z-[20] h-full'>
      {menuData?.map((item: any, index: number) => {
        return <MenuBarItem key={item.id + "_" + index} index={index} item={item} />
      })}
    </div>
  }

  const MenuParentMobile = ({ item = null, index = 0, isChild = false, }: any) => {
    index += 1;
    const [isOpen, setIsOpen] = useState(false);

    function handleOpenClose() {
      setIsOpen(!isOpen);
    }

    let props: any = {};
    if (item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '')) {
      props.href = host + item.url
    } else {
      props.href = ""
    }

    return <div className='menu--mobile--menu-zone--parent-zone '>
      <div className='menu--mobile--menu-zone--parent' style={{ paddingLeft: !isChild ? "16px" : "0" }}>
        <Link onClick={() => { setOpenMenuMobile(false) }}
          //{...props} 
          href={{ pathname: item?.url === "/" ? "/" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
          // href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
          className='flex flex-row'>
          {/* <div className='menu--mobile--menu-zone--parent--icon'>
            <Image
              src="/asset/icon/ico_sp.svg"
              alt="sp"
              width={24}
              height={24}
            />
          </div> */}
          <span className={'menu--mobile--menu-zone--parent--content ' + (!isChild ? "uppercase" : index === 2 ? "uppercase" : "")}>{item?.name ?? ""}</span>
        </Link>
        <div className='w-8 flex justify-end' onClick={() => {
          if (item?.children && item.children?.length > 0) {
            handleOpenClose()
          }
        }}>
          {item?.children && item.children?.length > 0 ? !isOpen ? <Image
            src="/asset/icon/ico_vector_down_menu.svg"
            alt="open menu"
            width={24}
            height={24}
          /> : <Image
            src="/asset/icon/ico_vector_up_menu.svg"
            alt="close menu"
            width={24}
            height={24}
          /> : <></>}
        </div>
      </div>
      {isOpen && <MenuChildZoneMobile setIsOpen={setIsOpen} item={item} index={index} isChild={isChild} />}
    </div>
  }
  const MenuChildZoneMobile = ({ index, item, setIsOpen, isChild }: any) => {
    return <div style={{ marginLeft: `${index !== 2 ? 24 : 12}px` }} className='menu--mobile--menu-zone--child-zone'>
      {
        item?.children?.map((item: any) => {
          return <MenuChildMobile index={index} isChild={isChild} setIsOpen={setIsOpen} key={item.id} item={item} />
        })
      }
    </div>
  }
  const MenuChildMobile = ({ index, item, setIsOpen, isChild }: any) => {

    let props: any = {};
    if (item?.url?.replace(/^\/+/, '')?.replace(/^\/+/, '')) {
      props.href = host + item.url
    } else {
      props.href = ""
    }
    return <>{item?.children && item.children.length > 0 ? <MenuParentMobile index={index} setIsOpen={setIsOpen} key={item.id} item={item} isChild={true} /> : <Link
      onClick={() => { setOpenMenuMobile(false) }}
      //{...props}
      href={{ pathname: item?.url === "/" ? "/" : '/[...group]', query: { group: item?.url === "/" ? [] : item?.url?.split("/").filter((e: any) => e !== '') } }}
      // href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
      className={'menu--mobile--menu-zone--child ' + (!isChild ? "border-0" : "")}>
      <span className={'menu--mobile--menu-zone--child--content ' + (!isChild ? "text-[1rem] uppercase" : "text-base ")}>{item?.name ?? ""}</span>
    </Link>}</>
  }

  const handleClickOutside = (event: any) => {
    if (mobileRef.current && !mobileRef.current?.contains(event.target)) {
      onCloseMenuMobile();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
    <div className="header h-[50px] md:h-[64px] flex justify-center">
      <div className='header-component h-[50px] md:h-[64px]'>
        <div className="flex flex-row items-center h-full flex-1">
          <div onClick={onOpenMenuMobile} className='toggle-ham'>
            <label className="hamb">
              <span className="hamb-line"></span>
              <span className="hamb-line"></span>
              <span className="hamb-line"></span>
            </label>
          </div>

          <Link
            onClick={()=>{window.location.href = "/"}}
            // href={{ pathname: '/', query: {} }}
            href={""}
            className="header-component-content--logo"
            title="Trang chủ"
            shallow={true}>
            <Image
              priority={true}
              className="mr-0 md:mr-[30px] xl:mr-[90px] flex w-[140px] xl:w-[160px]"
              src="/asset/icon/logo.svg"
              alt="Logo shop"
              width={197}
              height={50}
            />
          </Link>

          <div className='header-component-content header-component-content--menu h-full'>
            <Menu />
          </div>
        </div>
        <div className='header-component-content flex flex-row items-center justify-end gap-4 header-component-content--search'>
          <InputSearch host={host} />
          {/* <Image
            className="header-component-content--search--icon-search header-component-content--search--icon"
            src="/asset/icon/ico_search_menu.svg"
            alt="Search"
            width={24}
            height={24}
          /> */}
          <Link
            //href={pathRouter.CART}
            href={{ pathname: '/[...group]', query: { group: ['carts'] } }}
            className="flex items-center overflow-unset">
            <Badge count={(rentCarts?.length ?? 0) + (sellCarts?.length ?? 0)} size={'small'} className="custom-badge overflow-unset">
              <Image
                className="header-component-content--search--icon"
                src="/asset/icon/ico_buyer.svg"
                alt="Cart"
                width={24}
                height={24}
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </Badge>

          </Link>
          <Image
            onClick={() => { setOpenMaintain(!openMaintain) }}
            className="header-component-content--search--icon cursor-pointer"
            src="/asset/icon/ico_profile.svg"
            alt="Profile"
            width={24}
            height={24}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          />

        </div>
      </div>
      {openMaintain && <DynamicPopupMaintain setIsOpen={() => { setOpenMaintain(!openMaintain) }} />}
    </div>
    {openMenuMobile && <div id='menu--mobile' ref={mobileRef} className='menu--mobile'>
      <div className='menu--mobile--logo-zone'>
        <Image
          className="menu--mobile--logo-zone--logo"
          src="/asset/icon/logo.svg"
          alt="Logo shop"
          width={197}
          height={50}
        />
        <div className='menu--mobile--logo-zone--close'>
          <Image
            onClick={onOpenMenuMobile}
            src="/asset/icon/ico_close_menu.svg"
            alt="close menu"
            width={24}
            height={24}
          />
        </div>
      </div>
      <div className='menu--mobile--menu-zone'>
        {
          menuData?.map((item: any, index: number) => {
            return <div key={item.id} className={
              `
              ${index !== 0 ? "border-t-[1px] border-t-[#F0F0F0]" : ""}
              `
            }>
              <MenuParentMobile key={item.id} item={item} />
            </div>
          })
        }
      </div>
    </div>}
    </>
  )
}

const InputSearch = ({ host = "" }: any) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      // 👇️ your logic here
      router.push(host + "search?s=" + event.target.value);
    }

  }

  function onClose() {
    // setTimeout(() => {
    //   setOpen(false)
    // }, 800)
  }

  // Effect for API call
  useEffect(() => {
    if (debouncedSearchTerm) {
      searchMain(debouncedSearchTerm).then((results: any) => {
        setSearchData(results ?? []);
      });
      searchKeyword(debouncedSearchTerm).then((results: any) => {
        setKeywordData(results ?? []);
      });
    } 
    // else {
    //   setSearchData([]);
    // }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    // if (!open) {
    //   setSearchData([]);
    //   setSearchTerm("");
    // }
  }, [open])

  return <>
    <Image
      priority={true}
      onClick={() => { setSearchData([]); setOpen(true) }}
      className="header-component-content--search--icon-search header-component-content--search--icon flex lg:hidden"
      src="/asset/icon/ico_search_menu.svg"
      alt="Search"
      width={24}
      height={24}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    />
    {!open && <div className='wrap-input input--search w-[200px] ' onClick={() => { setSearchData([]); setOpen(true) }}>
      <div className="input">
        <input className='w-[140px] xl:w-[140px]' placeholder="Nhập tìm kiếm" />
      </div>
      <div className="icon">
        <Image
          className=""
          src="/asset/icon/ico_search.svg"
          alt="Search icon"
          width={12.5}
          height={12.5}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      </div>
    </div>}
    {
      open && <div
        onBlur={onClose}
        className='hidden md:flex flex-row absolute right-[-2rem] xl:right-[2rem] md:w-[87%] xl:w-[85%] h-[64px] py-[10px] items-center gap-4 z-[11]'>
        <input onKeyDown={handleEnter} defaultValue={searchTerm} autoFocus onChange={(e: any) => {
          setSearchTerm(e.target.value)
        }} type="text" className='w-[80%] text-sm text-[#000000] focus:outline-none px-4 py-3 bg-[#f5f5f5] border-[1px] border-[#f5f5f5] focus:border-[#1890FF] rounded-md' />
        <Image
          onClick={() => { setSearchData([]); setOpen(false) }}
          className="cursor-pointer"
          src="/asset/icon/ico_close.svg"
          alt="Search icon"
          width={48}
          height={48}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      </div>
    }
    {
      open && <div onBlur={onClose} className='flex md:hidden left-4 w-[100vw] flex-row absolute h-[64px] py-[10px] items-center gap-4 z-[11] bg-white'>
        <input
          autoFocus
          onKeyDown={handleEnter}
          onChange={(e: any) => {
            setSearchTerm(e.target.value)
          }}
          type="text"
          className='w-[80%] text-sm text-[#000000] focus:outline-none px-4 py-3 bg-[#f5f5f5] border-[1px] border-[#f5f5f5] focus:border-[#1890FF] rounded-md' />
        <Image
          onClick={() => { setSearchData([]); setOpen(false) }}
          className="cursor-pointer"
          src="/asset/icon/ico_close.svg"
          alt="Search icon"
          width={48}
          height={48}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        />
      </div>
    }
    {
      open && <div className='absolute shadow-lg top-[50px] xl:top-[64px] left-0 w-full bg-white z-[11] px-4 md:px-[12rem] xl:px-[14rem] pt-[2rem] flex flex-col md:flex-row overflow-y-auto'>
        <div className='flex flex-col'>
          <span className='text-xl text-[#434343] font-medium mb-[2rem]'>Gợi ý sản phẩm</span>
          <div className="grid grid-cols-2 gap-4">
            {
              searchData?.map((item: any) => {
                return <Link
                  key={item.id}
                  title={item?.name}
                  className='flex flex-row items-center gap-[8px] mb-4 items-center'
                  //href={host + item.url}
                  href={{ pathname: '/[...group]', query: { group: item?.url?.split("/").filter((e: any) => e !== '') } }}
                >
                  {item.file?.file_url ? <Image sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw" width={44} height={44} className='w-[44px] h-[44px] rounded-md' src={(environment.baseImageUrl + item.file?.file_url) ?? ""} title={item.file?.alt} alt={item.file?.alt} /> : <div className='w-[44px] h-[44px] rounded-md bg-[#8c8c8c] animate-pulse' />}
                  <p className='font-medium text-base text-[#434343] break_2 flex-1'>{item?.name}</p>
                </Link>
              })
            }
          </div>
          <div className="flex flex-row wrap-search-keyword flex-wrap gap-2 items-center mb-4">
            <span className='text-xl text-[#434343] font-medium'>Từ khoá: </span>
              {
                keywordData?.map((item: any, index:number) => {
                  return <Link
                    key={item.id}
                    title={item?.keyword}
                    className=''
                    //href={host + item.url}
                    href={"/tu-khoa/" + item?.code}
                  >
                    {item?.keyword + (index + 1 == keywordData.length ? "" : ",")}
                  </Link>
                })
              }
          </div>
          
        </div>
      </div>
    }
  </>
}

export default HeaderComponent;
